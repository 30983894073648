.homeSection{
    background-color: #132C47;
    color: white;
    padding: 1rem 4rem;
    display: flex;
    flex-wrap: wrap;
    background-image: url('./w.png');
}
.reviewSection{
    display: flex;
    color: #333;
    background:  #E3B679;
    padding: 1rem;
    box-shadow: 0 0 1rem #000 inset;
    overflow-x: auto;
    position: relative;
  
  &::-webkit-scrollbar {
    display: none;
  }
}
.text-container{
    width: 640px;
}
.text-large{
    padding-top: 4rem;
    padding-bottom: 2rem;
    font-family: Inter;
    font-size: 60px;
    font-weight: 600;
    line-height: 72px;
    letter-spacing: -0.04em;
    text-align: left;
}

.text-small{
    padding-bottom: 4rem;
    font-family: Inter;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
}

.phoneImage img{
    padding-top: 4rem;
    padding-bottom: 1rem;
    height: 560px;
    width: 590px;
}

.downloadButton{
    display: flex;
    align-items: flex-start;
}

a img{
    width: 174px;
    height: 52px;
    padding-right: 2rem;
}

.promo-link{
    color: white;
    text-decoration: none;
    padding: 1rem 0.5rem;
}
.promo-link:hover{
    color: rgb(242, 216, 151);
    text-decoration: none;
    padding: 1rem 0.5rem;
}

.press-container{
    width: 100%;
    padding: 2rem 0;;
}

.press-container-header{
    font-size: 36px;
    font-weight: 500;
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.press-image-container{
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
}

.press-image a img{
    padding: 0rem;
}

.press-image img{
    width: 160px;
    height: 56px;
    margin: 0 1rem;
    border-radius: 6px;
}

.promo-container .list{
    margin:  0 auto;
    width: 640px;
}

.promo-container .list li{
    font-size: 18px;
}

.animate-text {
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
    opacity: 0;
    animation: revealText 3s linear forwards; /* Adjust animation duration as needed */
  }
  
  @keyframes revealText {
    0% {
      width: 0;
      opacity: 0;
    }
    100% {
      width: 100%;
      opacity: 1;
    }
  }

@media (max-width: 800px) {
    .homeSection{
        padding: 1rem 1rem;
        justify-content: center;
    }
    .text-container{
        width: calc(100% - 2rem);
    }
    .phoneImage img{
        padding-top: 8rem;
        padding-bottom: 4rem;
        height: 360px;
        width: 100%;
    }
    .downloadButton{
        display: flex;
        justify-content: center;
    }

    a img{
        width: 150px;
        height: 52px;
        padding: 0rem 0.6rem;
    }
    .press-image img{
        width: 140px;
        height: 56px;
        margin: 0 1rem;
        border-radius: 6px;
    }

    .reviewSection{
        padding: 0.5rem;
    }
  
    .promo-container .list{
        margin:  0 auto;
        width: 100%;
    }
  }