.graphContainer{
  display: flex;
  flex-wrap: wrap;
  width: calc(100vw - 2rem);
  justify-content: space-around;
}
.chartTitle{
  margin: 0;
}
.graph{
  margin: 2rem 1rem;
  background-color: azure;
  border-radius: 12px;
  width: calc(50% - 4rem);
}

.groupGraphText{
  width: 100vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.graphTextContainer{
  background-color: azure;
  color: #132C47;
  padding: 1rem 1rem;
  margin: 1rem 2rem;
  border-radius: 6px;
  width: 108px;
}
.graphTextContainer h1{
  padding: 0;
  margin: 0;
}
.graphTextContainer h4{
  padding: 0;
  margin-top: 4px;
  margin-bottom: 4px;
  color:  #E3B679;
}

.chartTitle{
  color: #132C47;
  width: '100%';
  margin: '2rem'; 
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
}

.map-container {
  margin: 2rem 1rem;
  background-color: azure;
  border-radius: 12px;
  width: calc(50% - 4rem);
  filter: grayscale(100%); /* Apply grayscale filter */
}

@media (max-width: 800px) {
  .graphContainer{
    margin: 0;
    padding: 0;
    justify-content: center;
  }
  .graphTextContainer{
    background-color: azure;
    color: #132C47;
    padding: 1rem 1rem;
    margin: 1rem 0.5rem;
    border-radius: 6px;
    width: 120px;
  }

  .graph{
    padding: 0.5px !important;
    margin: 0.5rem 0.5rem;
    background-color: azure;
    border-radius: 12px;
    width: 100%;
    text-align: center;
  }
}