footer{
    background-color: white;
    color: black;
    padding: 1rem 4rem;
}
.socialMedia{
    width: 2em;
    height: 2em;
    padding: 1rem 2rem;
}
.link{
    color: black;
    text-decoration: none;
    padding: 1rem 0.5rem;
}
.substack-item ::marker{
    display: none;
}
.substack-item .substack-watermark{
    display: none !important;
}
.substack-item iframe{
    border:1px solid white; background:#E3B679 !important;
    width: 360px; height: 150px;
}
.substack-item .button.primary{
    background:#E3B679 !important;
}
.active{
    color: #E3B679;
}
.link:focus,  .link:hover{
    color: #E3B679;
}
.footer p{
    font-size: 16px !important;
    color: #868787;
}

@media (max-width: 800px) {
    footer {
      padding: 1rem 1rem;
    }
    .footer p{
        font-size: 14px !important;
        color: #868787;
    }
  }