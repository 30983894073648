.impactSection{
  padding: 1rem 1rem;
  background-color: #132C47;
  color: white;
  padding: 1rem 1rem;
  display: flex;
  background-repeat: repeat;
}
.impactSubSection{
  margin: auto;
}
.impactTextBody{
  padding: 2rem;
}
.impactTitle1{
  font-size: 24px;
  font-weight: 500;
}
.impactTitle2{
  font-size: 22px;
  font-weight: 500;
}
.impactTitle3{
  font-size: 20px;
  font-weight: 500;
}
.impactList{
  text-align: left;
}
.impactBody{
  font-size: 20px;
}
.impactList li{
  font-size: 18px;
}
@media (max-width: 800px) {
  .impactSection{
      padding: 1rem 0.5rem;
  }
  .impactTextBody{
    padding: 1rem 0;
  }

}