.privacySection{
  padding: 1rem 1rem;
  background-color: #132C47;
  color: white;
  padding: 1rem 4rem;
  text-align: left;
  line-height: 1.2;
}
.title{
  font-weight: bold;
}
.privacySection li, p{
  font-size: 18px;
  font-weight: normal;
  line-height: 1.5;
}
.privacySection .link{
  color: antiquewhite;
  text-decoration: none;
  padding: 1rem 0.5rem;
}
.link:focus,  .link:hover{
  color: #E3B679;
}

@media (max-width: 800px) {
  .privacySection{
      padding: 1rem 1rem;
  }
}