.review-card {
  height: 360px;
  border: 1px solid #e0e0e0;
  padding: 20px;
  margin: 10px;
  color: black;
  background-color:white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

}

.user-section{
  display: flex;
  width: 300px; 
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.user-profile {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-profile img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border-color: #888;
  border-width: 1px;
  margin-right: 10px;
}



.user-name {
  font-weight: bold;
  font-size: 18px;
}

.item-section img {
  width: 140px;
  height: 120px;
  margin-bottom: 10px;
}

.review-content {
  width: 100%;
  margin: 0;
  font-size: 16px;
  text-overflow: ellipsis;
  overflow-y: auto;
  display: -webkit-box;
  -webkit-line-clamp: 7; /* number of lines to show */
          line-clamp: 7; 
  -webkit-box-orient: vertical;
}

.rating-section {
  padding-top: 0.5rem;
  font-size: 12px;
  display: flex;
  justify-content: end;
  align-items: end;
}

.review-date {
  color: #888;
}

@media (max-width: 800px) {
  .review-card {
    height: 360px;
  }
  
  .user-section{
    display: flex;
    width: 250px;
  }
}