/* Header.css */

.header {
    background-color: #132C47;
    color: white;
    padding: 1rem 4rem;
    display: flex;
    justify-content: space-between;
  }
  
  .nav {
    display: flex;
    justify-content: center;
  }
  .brand-image img{
    height: 48px;
    width: 48px;
  }
  .nav-list {
    list-style: none;
    display: flex;
  }
  
  .nav-item {
    margin: 0 1rem;
    font-size: 18px;
  }
  
  .nav-link {
    color: white;
    text-decoration: none;
    transition: color 0.3s;
  }
  .active{
    color: #E3B679;
  }
  
  .nav-link:hover {
    color: lightgray;
  }
  

  @media (max-width: 800px) {
    .header {
      padding: 1rem 1rem;
    }
  }